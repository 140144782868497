import React, { useState, useEffect } from "react";

interface CheckboxProps {
    name?: string;
    checked: boolean;
    label?: string;
    onToggle?: any;
}


const CheckboxComponent = (props: CheckboxProps) => {
    const [checked, setChecked] = useState(props.checked);
    const toggle = () => {
        if (props.onToggle) {
            props.onToggle(checked)
        }
    }

    useEffect(() => {
        setChecked(props.checked);
    }, [props.checked])

    return (
        <div className={"check"}>
            <input name={props.name} type="checkbox" checked={checked} onChange={() => toggle()} />
            <label>{props.label}</label>
        </div>


    );
}

export default CheckboxComponent;
import React, { useState, useEffect } from "react";

interface SelectInputProps {
    text: string;
    name: string;
    options: string[];
    label: string;
    changeText: any;
    showLabel?: boolean;
    required?: boolean;
    placeholder?: string;
    validators?: ((value?: string) => string | null)[];
    handleFieldErrors?: (fieldName: string, errors: string[]) => void;
}
const defaultProps: SelectInputProps = {
    text: '',
    name: '',
    options: [],
    label: '',
    changeText: false,
    showLabel: true,
    required: false,
    placeholder: '',
    validators: undefined,
    handleFieldErrors: undefined,
}


const SelectInputComponent = (props: SelectInputProps) => {
    const [value, setValue] = useState(props.text ?? '');
    const [errors, setErrors] = useState<string[]>([]);

    const saveText = (e: any) => {
        e.preventDefault();
        setValue(e.target.value);
        if (props.changeText)
            props.changeText(e.target.value)
    }
    const handleOnBlur = () => {
        const fieldErrors: string[] = [];

        (props.validators ?? []).forEach((validator) => {
            const err = validator(value);


            if (err) {
                fieldErrors.push(err);
            }
        });
        setErrors(fieldErrors);

        props.handleFieldErrors?.(props.name!, fieldErrors);

    }

    useEffect(() => {
        setValue(props.text ?? '');
    }, [props.text]);

    return (
        <div className="input">
            {

                props.showLabel && <label>
                    {props.label}
                    {props.required &&
                        <span className="warn">
                            *
                        </span>
                    }
                </label>
            }
            <select
                value={value}
                onInput={(e: any) => saveText(e)}
                placeholder={props.placeholder}
                onChange={handleOnBlur}
                name={props.name}
            >
                {
                    props.options.map((option, index) => {

                        return (
                            <option key={`inputType_${index}`} value={option} >{option}</option>
                        )
                    })
                }

            </select>
            {errors.length > 0 && (
                <div className="error">{errors.map((err: string, index: number) => <div key={`err-${index}`}> - {err}</div>)}</div>
            )

            }
        </div>


    );
}
SelectInputComponent.defaultProps = defaultProps;

export default SelectInputComponent;
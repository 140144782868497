import React from "react";

const BackIcon = () => {
    return (
        <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.83016 14.9998C6.68077 15.0003 6.53316 14.9673 6.39818 14.9033C6.2632 14.8393 6.14428 14.7458 6.05016 14.6298L1.22016 8.62979C1.07308 8.45085 0.992676 8.22641 0.992676 7.99479C0.992676 7.76316 1.07308 7.53872 1.22016 7.35979L6.22016 1.35979C6.3899 1.15557 6.63381 1.02715 6.89824 1.00277C7.16267 0.978387 7.42595 1.06005 7.63016 1.22979C7.83438 1.39953 7.9628 1.64344 7.98718 1.90786C8.01156 2.17229 7.9299 2.43557 7.76016 2.63979L3.29016 7.99979L7.61016 13.3598C7.73245 13.5066 7.81012 13.6853 7.834 13.8749C7.85788 14.0644 7.82697 14.2568 7.74491 14.4294C7.66285 14.6019 7.53309 14.7473 7.37098 14.8484C7.20887 14.9495 7.0212 15.002 6.83016 14.9998Z" fill="#3B54A4" />
        </svg>)

};

export default BackIcon;

import axios from "axios";

const validators = {
    text: (value?: string, message?: string): string | null => {
        const valid = (value || "").trim().length > 0;

        return valid ? null : message || "Required";
    },
    url: (value?: string): string | null => {
        const val = (value || "").trim();

        if (val.length < 1) {
            return null;
        }

        const exp = new RegExp(
            /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)$/gi
        );

        const valid = exp.test(val);

        if (!valid) {
            return "Invalid URL";
        }
        return null;
    },
    email: (value?: string): string | null => {
        const val = (value || "").trim();

        if (val.length < 1) {
            return "Email Required";
        }

        const exp = new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

        const valid = exp.test(val);

        if (!valid) {
            return "Invalid Email";
        }
        return null;
    },

    //Phone  Validation

    phone: async (value?: string): Promise<string | null> => {
        const val = (value || "").trim();

        if (val.length < 1) {
            return new Promise((resolve, rj) => resolve("Phone Number Required"));
        }

        const exp = new RegExp(
            /^(\+1)[0-9]{10}$/im
        );

        const valid = exp.test(val);

        if (!valid) {
            return new Promise((resolve, rj) => resolve("Invalid Phone Number"));
        } else {
            return await axios.post(`${process.env.REACT_APP_URL_SUBMIT}/api/v2/partner-onboarding/valid-tel`, {number: val})
                .then(resp => {
                    if (resp.data.isValid === true)
                        return null;
                    else
                        return resp.data.error;
                })
                .catch(err => {
                    return 'Validation API not available';
                })
        }
    },
};


export {validators};

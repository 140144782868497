import React from "react";
import TextInput from "../Inputs/TextInput";
import SelectInput from "../Inputs/SelectInput";
import Button from "../Button";
import { validators } from './validators';
import { OfficeFormProps } from "./types";
import { Office } from "../../interfaces/Forms";
import { useApp } from "../../context/AppContext";
import { useForm } from "../../context/FormContext";


import { useParams } from "react-router-dom";

type ErrorsList = {
    [key in keyof Office]: {
        errors: string[];
    }
}
type OnboardingParams = {
    referral: string;
}

const OfficeForm = (props: OfficeFormProps) => {
    const form = useForm();

    const { referral } = useParams<OnboardingParams>();
    const [hasErrors, setHasErrors] = React.useState<boolean>(true);

    const initialErrors = () => {
        const _errs: any = {}
        const fields = ["name", "first", "last", "role"]

        for (var field of fields) {
            if (!form?.formState?.office) {
                _errs[field] = { errors: [''] }
                continue;
            }
            const _officeField = form.formState.office[field as "name" | "first" | "last" | "role"];
            if (_officeField && _officeField.length > 0)
                _errs[field] = { errors: [] }
            else
                _errs[field] = { errors: [''] }
        }
        return _errs
    }

    const [errors, setErrors] = React.useState<Partial<ErrorsList>>(initialErrors());
    const [officeFields, setOfficeFields] = React.useState<Partial<Office>>({});
    const App = useApp();




    const handleFieldErrors = (field: string, err: string[]) => {

        setErrors({
            ...errors,
            [field]: {
                errors: err,
            },
        });
    }

    const submitOffice = () => {
        if (!form) {
            return;
        }
        form.formState.office = { ...officeFields }
        form.setFormState(form.formState);
        form.next();

    }


    React.useEffect(() => {
        if (form?.formState.office) {
            if (referral)
                setOfficeFields({ ...form?.formState.office, referral: referral.toLowerCase() });
            else
                setOfficeFields({ ...form?.formState.office });
        }
    }, [form?.formState, referral])

    React.useEffect(() => {
        let _hasErrors = false;
        for (const key in errors) {
            const errorItem = errors[key as keyof Office];

            if (_hasErrors) break;

            if (errorItem && errorItem?.errors.length > 0) {
                _hasErrors = true;
            }
        }
        setHasErrors(_hasErrors);

    }, [errors])




    return (
        <div className="form small">
            <div className="head">
                <p className="subtitle">Enter your office information. This will be your main office account.</p>
                <p className="italic">
                    <span className="warn">*</span> Required Fields
                </p>
            </div>
            <div className="body">

                <div className="row">
                    <div className="col col-12">
                        <TextInput
                            label="Office Name"
                            required={true}
                            text={officeFields.name}
                            name="name"
                            validators={[
                                (value) => validators.text(value, 'Office Name Required'),
                            ]}
                            handleFieldErrors={handleFieldErrors}
                            changeText={
                                (text: string) => {
                                    officeFields.name = text;
                                    setOfficeFields({ ...officeFields })
                                }
                            } />
                    </div>
                </div>
                <div className="row">
                    <div className="col col-12">
                        <TextInput
                            label="Website"
                            text={officeFields.website}
                            name="website"
                            validators={[
                                validators.url
                            ]}
                            handleFieldErrors={handleFieldErrors}
                            changeText={
                                (text: string) => {
                                    officeFields.website = text;
                                    setOfficeFields({ ...officeFields })
                                }
                            } />
                    </div>
                </div>
                <div className="row">
                    <div className="col col-6">
                        <TextInput
                            label="First Name"
                            required={true}
                            text={officeFields.first}
                            name="first"
                            validators={[
                                (value) => validators.text(value, 'First Name Required')
                            ]}
                            handleFieldErrors={handleFieldErrors}
                            changeText={
                                (text: string) => {
                                    officeFields.first = text;
                                    setOfficeFields({ ...officeFields })
                                }
                            } />
                    </div>
                    <div className="col col-6">
                        <TextInput
                            label="Last Name"
                            required={true}
                            text={officeFields.last}
                            name="last"
                            validators={[
                                (value) => validators.text(value, 'Last Name Required')
                            ]}
                            handleFieldErrors={handleFieldErrors}
                            changeText={
                                (text: string) => {
                                    officeFields.last = text;
                                    setOfficeFields({ ...officeFields })
                                }
                            } />
                    </div>
                </div>
                <div className="row">
                    <div className="col col-12">
                        <SelectInput
                            label="Role"
                            required={true}
                            name="role"
                            text={officeFields.role}
                            options={App?.roles}
                            validators={[
                                (value) => validators.text(value, 'Role Required')
                            ]}
                            handleFieldErrors={handleFieldErrors}
                            changeText={
                                (text: string) => {
                                    officeFields.role = text;
                                    setOfficeFields({ ...officeFields })
                                }
                            } />
                    </div>
                </div>
            </div>
            <div className="control">
                <div className="row">
                    <Button onClick={submitOffice} disabled={hasErrors} className={"large"}>Next: Location</Button>
                </div>


            </div>

        </div>
    )

};

export default OfficeForm;
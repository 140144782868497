
import StyleGuide from "./pages/StyleGuide";
import Onboarding from "./pages/Onboarding";
import { Route } from "react-router-dom";
import { AppProvider } from "./context/AppContext";
import { FormProvider } from "./context/FormContext";
function App() {
  return (
    <div className="App">
      <AppProvider>
        <Route exact path="/styleguide" component={StyleGuide} />

        <FormProvider>
          <Route exact path="/" component={Onboarding} />
          <Route path="/:referral" component={Onboarding} />
        </FormProvider>

      </AppProvider>
      <p className="version">
        {`${process.env.REACT_APP_NAME} v${process.env.REACT_APP_VERSION}`}
      </p>
    </div>
  );
}

export default App;

import "./StyleGuide.scss";
import Logo from "../../assets/images/logo.svg";
import Button from "../../components/Button";
import TextInput from "../../components/Inputs/TextInput";
import SelectInput from "../../components/Inputs/SelectInput";
import Checkbox from "../../components/Inputs/Checkbox";

const StyleGuide = () => {
  const colors = [
    { color: "#3b54a4", name: "primaryColor" },
    { color: "#767676", name: "standardColor" },
    { color: "#eff0f7", name: "primaryLightColor" },
    { color: "#f01010", name: "warningColor" },
  ];

  return (
    <section className="styleGuidePage">
      <div className="contain">
        <div className="guideItem">
          <h4 className="title">Logo Usage</h4>
          <img className="logo" src={Logo} alt={"Alternative Text"} />
        </div>

        <div className="guideItem">
          <h4 className="title">Color Usage</h4>

          <div className="colors">
            {colors.map((colorObj: any, index: number) => {
              return (
                <div key={index} className="color">
                  <div
                    className="swatch"
                    style={{ background: colorObj.color }}
                  ></div>
                  <div className="info">
                    <p>{colorObj.name}</p>
                    <p>{colorObj.color}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="guideItem">
          <h4 className="title">Font Usage</h4>

          <div className="styleTypes">
            <div className="styleType">
              <p className="name">Title & Subtitle Large</p>
              <div className="style">
                <div className="titleSubtitle left">
                  <p className="subtitle">This is an example subtitle</p>
                  <h1>Example large title</h1>
                </div>
              </div>
            </div>
            <div className="styleType">
              <p className="name">Title & Subtitle</p>
              <div className="style">
                <div className="titleSubtitle left">
                  <p className="subtitle">This is an example subtitle</p>
                  <h2>Example large title</h2>
                </div>
              </div>
            </div>

            <div className="styleType">
              <p className="name">Body</p>
              <div className="style">
                <p>
                  This is an example body paragragh. This is an example body
                  paragragh. This is an example body paragragh. This is an
                  example body paragragh. This is an example body paragragh.
                  This is an example body paragragh. This is an example body
                  paragragh. This is an example body paragragh.
                </p>
              </div>
            </div>

            <div className="styleType">
              <p className="name">Italicized</p>
              <div className="style">
                <p className="italic">
                  <span className="warn">*</span> Italics
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="guideItem">
          <h4 className="title">Button Usage</h4>
          <div className="styleTypes">
            <div className="styleType">
              <p className="name">Large Button</p>
              <div className="style">
                <Button className={"large"}>Button Text</Button>
              </div>
            </div>
            <div className="styleType">
              <p className="name">Large Button(disabled)</p>
              <div className="style">
                <Button className={"large"} disabled={true}>Button Text</Button>
              </div>
            </div>
            <div className="styleType">
              <p className="name">Back Button</p>
              <div className="style">
                <Button className={"back"}>Button Text</Button>
              </div>
            </div>
          </div>

        </div>

        <div className="guideItem">
          <h4 className="title">Input Usage</h4>
          <div className="styleTypes">
            <div className="styleType">
              <p className="name">Text Input</p>
              <div className="style">
                <TextInput text={""} label={"Text Input"} />
              </div>
            </div>

            <div className="styleType">
              <p className="name">Disabled Text Input</p>
              <div className="style">
                <TextInput disabled={true} text={""} label={"Text Input"} />
              </div>
            </div>

            <div className="styleType">
              <p className="name">Required Text Input</p>
              <div className="style">
                <TextInput text={""} required={true} label={"Text Input"} />
              </div>

            </div>
            <div className="styleType">
              <p className="name">Required Text Input(Invalid)</p>
              <div className="style">
                <TextInput text={""} required={true} touch={true} label={"Text Input"} />
              </div>

            </div>
            <div className="styleType">
              <p className="name">Checkbox</p>
              <div className="style">
                <Checkbox checked={true} label={"Checked"} />
                <Checkbox checked={false} label={"Not Checked"} />
              </div>
            </div>
            <div className="styleType">
              <p className="name">Select Input</p>
              <div className="style">
                <SelectInput text={""} options={["Option 1", "Option 2", "Option 3"]} label={"Select Input"} />
              </div>
            </div>
            <div className="styleType">
              <p className="name">Required Select Input</p>
              <div className="style">
                <SelectInput text={""} required={true} options={["Option 1", "Option 2", "Option 3"]} label={"Select Input"} />
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default StyleGuide;

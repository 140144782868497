import React from "react";
import {useParams} from "react-router-dom";
import OnboardingIcons from "../../components/Icons/OnboardingIcons";
import Header from "../../components/Header";

import OfficeForm from "../../components/Forms/Office";
import LocationForm from "../../components/Forms/Location";
import ScannerForm from "../../components/Forms/Scanner";
import AccountForm from "../../components/Forms/Account";

import {Location, Office} from "../../interfaces/Forms";
import {useForm} from "../../context/FormContext";
import ErrorTransformer from "../../components/Errors/ErrorTransformer";

type onboardingParams = {
    referral: string;
}

const OnboardingPage = () => {
    const form = useForm();
    const {referral} = useParams<onboardingParams>();
    const newOffice: Office = {
        name: '',
        website: '',
        first: '',
        last: '',
        role: '',
        email: '',
        locations: [{
            name: '',
            tel: '',
            text_address: '',
            line_one: '',
            line_two: '',
            city: '',
            state: '',
            zip: '',
            lat: 0,
            lng: 0,
            technology: '',
        }],
        referral: referral,
    }


    const [office, setOffice] = React.useState<Office>(newOffice)

    const submitOffice = (office: Office) => {
        setOffice({...office})
    }

    const submitLocations = (locations: Location[]) => {
        office.locations = locations
        setOffice({...office})
    }
    const setEmail = (email: string) => {
        office.email = email;
        setOffice({...office})
    }


    return (
        <>
            <Header/>
            <section className="onboardingPage">
                {form?.loading ?
                    <div className="contain loading">
                        <div className="center titleSubtitle">
                            <h1>Form Being Submitted...</h1>
                            <p className="subtitle">This may take a few moments...</p>
                        </div>
                        <div className="loading">

                        </div>
                    </div>


                    :
                    <div className="contain">
                        {form?.step !== undefined && form.step < 4 ?
                            <>
                                <div className="center titleSubtitle">

                                    {form?.step === 0 &&
                                        <>
                                            <p className="subtitle">Welcome to Retainer Club</p>
                                            <h1>Let's get started </h1>
                                        </>
                                    }
                                    {form?.step === 1 &&
                                        <>
                                            <p className="subtitle">Welcome to the Retainer Club family</p>
                                            <h2>Nice to meet you {form?.formState?.office.first}! Where are your
                                                Offices? </h2>
                                        </>
                                    }
                                    {form?.step === 2 &&
                                        <>
                                            <p className="subtitle">Welcome to the Retainer Club family</p>
                                            <h2>Great job! What 3D scanner Do You Use?</h2>
                                        </>
                                    }
                                    {form?.step === 3 &&
                                        <>
                                            <p className="subtitle">Welcome to the Retainer Club family</p>
                                            <h1>Almost Finished</h1>
                                        </>
                                    }
                                </div>
                                <div className={"progress"}>
                                    <div className="tabs">
                                        <div className="tab" onClick={() => {
                                            if (form.step > 0) form.setStep(0)
                                        }}>
                                            <OnboardingIcons step={0} current={form.step}/>
                                            Office
                                        </div>
                                        <div className="tab" onClick={() => {
                                            if (form.step > 1) form.setStep(1)
                                        }}>
                                            <OnboardingIcons step={1} current={form.step}/>
                                            Location
                                        </div>
                                        <div className="tab" onClick={() => {
                                            if (form.step > 2) form.setStep(2)
                                        }}>
                                            <OnboardingIcons step={2} current={form.step}/>
                                            Scanner
                                        </div>
                                        <div className="tab">
                                            <OnboardingIcons step={3} current={form.step}/>
                                            Account
                                        </div>
                                    </div>

                                    <div className={"bar"}>
                                        <span style={{width: ((form.step + 1) / 4) * 100 + '%'}}></span>
                                    </div>
                                </div>
                                {
                                    form.step === 0 && <OfficeForm submit={submitOffice} office={office}/>
                                }
                                {
                                    form.step === 1 &&
                                    <LocationForm submitLocations={submitLocations} locations={office.locations}/>
                                }
                                {
                                    form.step === 2 &&
                                    <ScannerForm submitScanners={submitLocations} scanners={office.locations}/>
                                }
                                {
                                    form.step === 3 &&
                                    <AccountForm submit={(email) => setEmail(email)} email={office.email}/>
                                }
                            </>
                            :
                            form?.company ?
                                <div className={"formSubmitted"}>
                                    <div className="center titleSubtitle">
                                        <p className="subtitle">Welcome to the Retainer Club family</p>
                                        <h1>Success!</h1>
                                        <p className="subtitle">Your information has been successfully been
                                            submitted.</p>
                                        {/*
                                        <p className="subtitle">Please check your email to validate your account</p>
                                        <div className="col col-12 ">
                                            <Button onClick={() => form?.submit()} className={"link"} >Resend Email</Button>
                                        </div>
                                    */}
                                    </div>
                                </div>
                                :
                                <div className="center titleSubtitle">
                                    <ErrorTransformer/>
                                </div>
                        }
                    </div>
                }
            </section>

        </>
    )
};

export default OnboardingPage

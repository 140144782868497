import React from "react";
import TextInput from "../Inputs/TextInput";
import Button from "../Button";
import SelectInput from "../Inputs/SelectInput";

import { useApp } from "../../context/AppContext";
import { useForm } from "../../context/FormContext";
import { Location } from "../../interfaces/Forms";
interface ScannerType {
    name: string;
    technology: string;
}

const ScannerForm = (props: any) => {
    const form = useForm();

    const [validated, setValidated] = React.useState(false)
    const [scanners, setScanners] = React.useState<Location[]>([])

    const updateScanner = (index: number, scanner: Location) => {
        if(scanner.technology === "We don't have a scanner yet") scanner.technology=null;
        scanners[index] = scanner;
        setScanners([...scanners])

    }


    const submitScanners = (goNextPage:boolean) => {
        if (!form)
            return

        form.formState.office.locations = ([...scanners])
        form.setFormState({ ...form.formState })
        goNextPage && form.next();
        !goNextPage && form.back();
    }

    React.useEffect(() => {
        if (form?.formState.office.locations)
            setScanners([...form.formState.office.locations]);
    }, [form?.formState])

    React.useEffect(() => {
        const valid = scanners.findIndex(scanner => scanner.technology === '') < 0;
        setValidated(valid)
    }, [scanners])

    return form?.formState?.office?.locations ? (
        <div className="form">
            <div className="head">
                <p className="subtitle">Select the type of technology/scanner used at your location(s). You can select the technology of partner locations in the admin later.</p>
                <p className="italic">
                    <span className="warn">*</span> Required Fields
                </p>
            </div>

            {
                form.formState.office.locations.map((scanner: any, index: number) => {

                    return (
                        <Scanner key={index} index={index} scanner={scanner} updateScanner={(scanner: Location) => updateScanner(index, scanner)} final={index === (scanners.length - 1)} first={index === 0} />
                    )
                })
            }
            <div className="control">
                <div className="row">
                    <Button
                        disabled={!validated}
                        onClick={()=>submitScanners(true)}
                        className={"large"}>
                        Next: Account
                    </Button>
                </div>
                <div className="row">
                    <Button
                        onClick={()=>submitScanners(false)}
                        className={"back"}>
                        Back
                    </Button>
                </div>


            </div>

        </div>
    ) : (<></>);

};
interface ScannerProps {
    first: boolean;
    final: boolean;
    scanner: ScannerType;
    updateScanner: any;
    index: number;
}

const Scanner = (props: ScannerProps) => {
    const [scanner, setScanner] = React.useState<ScannerType>(props.scanner);
    const App = useApp();

    const updateScannerTech = (value: string) => {
        scanner.technology = value;
        props.updateScanner({ ...scanner })
    }
    React.useEffect(() => {
        setScanner({ ...props.scanner })
    }, [props.scanner])

    return (
        <div className="body">
            <div className="row">
                <div className="col col-7">
                    <TextInput
                        text={props.scanner.name}
                        name={"locationName_" + props.index}
                        label="Location Name"
                        disabled={true} />

                </div>
                <div className="col col-5">
                    <SelectInput
                        text={props.scanner.technology === null ? "We don't have a scanner yet" : props.scanner.technology}
                        name={"technology_" + props.index}
                        options={App?.technologies}
                        label="Technology"
                        required={props.first}
                        changeText={
                            (text: string) => {
                                updateScannerTech(text);
                            }
                        } />
                </div>
            </div>
            {/* <div className="row small">
                <Checkbox
                    name={"hasScanner_" + props.index}
                    checked={!scanner.hasScanner}
                    onToggle={(value: boolean) => { updateScanner(value) }}
                    label={"We dont have a scanner yet."} />

            </div> */}
            {!props.final && <hr />}
        </div>
    )

};


export default ScannerForm;

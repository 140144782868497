import React, {useEffect, useState,} from "react";
import InputMask from "react-input-mask";
import {omit} from 'lodash';


interface TextInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    text?: string;
    label?: string;
    changeText?: any;
    mask?: string;
    touch?: boolean;
    disabled?: boolean;
    permanents?: any;
    type?: any;
    validators?: ((value?: string) => string | null | Promise<string | null>)[];
    handleFieldErrors?: (fieldName: string, errors: string[]) => void;
}


const TextInputComponent = (props: TextInputProps) => {
    const [errors, setErrors] = useState<string[]>([]);
    const [showErr, setShowErr] = useState<boolean>(false);
    // const [touch, setTouch] = useState(props.touch ? props.touch : false);
    const [value, setValue] = useState<string>(props.text ?? '');

    const saveText = (val: string) => {
        // setTouch(true);
        handleOnChange(val);
        if (props.changeText)
            props.changeText(val);

    }

    const handleOnChange = async (val: string) => {
        const fieldErrors: string[] = [];
        for (const validator of (props.validators ?? [])) {
            const err = await validator(val);

            if (err) {
                fieldErrors.push(err);
            }
        }

        setErrors(fieldErrors);
        props.handleFieldErrors?.(props.name!, fieldErrors);

    }

    const handleOnBlur = () => {
        if (errors.length > 0) {
            setShowErr(true)
        } else
            setShowErr(false)
    }


    useEffect(() => {
        if (props.text) {
            setValue(props.text)
            handleOnChange(props.text)
        } else {
            setValue('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.text]);


    return (
        <div className="input">
            {props.label &&
                <label>
                    {props.label}
                    {props.required &&
                        <span className="warn">
                            *
                        </span>
                    }
                </label>
            }
            {
                props.mask ?
                    <InputMask
                        className={`${errors.length > 0 ? "inputError" : ""}`}
                        mask={props.mask}
                        type={props.type ?? 'text'}
                        placeholder={props.mask}
                        value={value}
                        onFocus={() => handleOnChange(value)}
                        onChange={(e: any) => saveText(e.target.value.split('_').join(''))}
                        disabled={props.disabled ? props.disabled : false}
                        autoComplete={props.autoComplete ?? ''}
                        onBlur={handleOnBlur}
                        name={props.name}
                    ></InputMask>

                    :
                    <input
                        className={`${errors.length > 0 ? "inputError" : ""}`}
                        type="text"
                        value={value}
                        onFocus={() => handleOnChange(value)}
                        onInput={(e: any) => saveText(e.target.value)}
                        placeholder={props.placeholder}
                        disabled={props.disabled ? props.disabled : false}

                        onBlur={handleOnBlur}
                        {...omit(props, [
                            'changeText',
                            'handleFieldErrors',
                            'permanents',
                            'type'
                        ])}
                    ></input>

            }
            {errors.length > 0 && showErr && (
                <div className="error">{errors.map((err: string, index: number) => <div
                    key={`err-${index}`}> - {err}</div>)}</div>
            )

            }

        </div>


    );
}

export default TextInputComponent;

import React from "react";
import TextInput from "../Inputs/TextInput";
import Button from "../Button";
import GoogleAddressInput from "../Inputs/GoogleAddressInput";
import {validators} from './validators'
import {Location} from "../../interfaces/Forms";
import {useGeo} from "../../context/GeoContext";

interface LocationFormProps {
    first: boolean;
    final: boolean;
    addLocation: any;
    removeLocation: any;
    index: number;
    hasErrors: (hasErrors: boolean) => void;
}

type ErrorsList = {
    [key in keyof Location]: {
        errors: string[];
    }
}

const LocationForm = (props: LocationFormProps) => {
    const geo = useGeo();
    //const [location, setLocation] = React.useState(props.location)


    const initialErrors = () => {
        const _errs: any = {}
        const fields = ["name", "tel", "line_one", "city", "state", "zip"]

        for (var field of fields) {
            if (!geo?.selectedLocation) {
                _errs[field + '_' + props.index] = {errors: ['']}
                continue;
            }
            if (geo.selectedLocation[field as "name" | "tel" | "line_one" | "city" | "state" | "zip"].length > 0)
                _errs[field + '_' + props.index] = {errors: []}
            else
                _errs[field + '_' + props.index] = {errors: ['']}
        }
        return _errs
    }


    const [errors, setErrors] = React.useState<Partial<ErrorsList>>(initialErrors());
    const [complete, setComplete] = React.useState<boolean>(false);
    //
    const handleFieldErrors = (field: string, err: string[]) => {
        setErrors(preValue => ({
            ...preValue,
            [field]: {
                errors: err,
            },
        }));
    }


    const setLocationField = (value: string | boolean, field: keyof Location) => {
        geo!.updateSelectedLocation({
            [field as keyof Location]: value
        })
    }

    const autoFillAddress = (address: any) => {
        const loc = geo!.selectLocationFromAddress(address);
        const _errors: any = {}
        if (loc.city.length > 0) {
            _errors['city_' + props.index] = {errors: []};
        }


        if (loc.state.length > 0) {
            _errors['state_' + props.index] = {errors: []};
        }

        if (loc.zip.length > 0) {
            _errors['zip_' + props.index] = {errors: []};
        }
        ;

        if (loc.line_one.length > 0) {
            _errors['line_one_' + props.index] = {errors: []};
        }


        const newErrors = {
            ...errors,
            ..._errors
        }
        setErrors(newErrors);
    }

    const setHasErrors = (err: boolean) => {
        props.hasErrors(err);
        setComplete(!err);
    }

    React.useEffect(() => {
        let _hasErrors = false;
        for (const key in errors) {
            const errorItem = errors[key as keyof Location];

            if (_hasErrors) break;

            if (errorItem && errorItem?.errors.length > 0) {
                _hasErrors = true;
            }
        }
        setHasErrors(_hasErrors);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors])


    return geo ? (
        <div className="body">
            {!props.first &&
                <div className="remove">
                    <span onClick={() => props.removeLocation()}>
                  Remove location
                    </span>
                </div>
            }
            <div className="row">
                <div className="col col-6">
                    <TextInput
                        label={`${props.first ? "Primary " : ""}${"Location Name"}`}
                        name={"name_" + props.index}
                        required={true}
                        autoComplete={"off"}
                        text={geo.selectedLocation.name}
                        validators={[
                            (value) => validators.text(value, 'Location Name Required'),
                        ]}
                        handleFieldErrors={handleFieldErrors}
                        changeText={
                            (text: string) => {
                                setLocationField(text, 'name')
                            }
                        }
                    />
                </div>
                <div className="col col-6">
                    <TextInput
                        label={`${props.first ? "Primary " : ""}${"Office Number"}`}
                        name={"tel_" + props.index}
                        mask="+19999999999"
                        required={true}
                        autoComplete={"off"}
                        text={geo.selectedLocation.tel}
                        validators={[
                            (value) => validators.phone(value),
                        ]}
                        handleFieldErrors={handleFieldErrors}
                        permanents={[0, 1]}
                        type={"tel"}
                        changeText={
                            (text: string) => {
                                setLocationField(text, 'tel');
                            }
                        }
                    />
                </div>
            </div>
            <div className="row">
                <div className="col col-12">
                    <GoogleAddressInput
                        required={true}
                        validators={[
                            (value) => validators.text(value, 'Street Address Required'),
                        ]}
                        changeAddress={(value: any) => setLocationField(value, 'line_one')}
                        autoFillAddress={(value: any) => autoFillAddress(value)}
                        handleFieldErrors={handleFieldErrors}
                        address={geo.selectedLocation.line_one}
                        label={`${props.first ? "Primary " : ""}${"Address Line 1"}`}
                        name={"line_one_" + props.index}
                    />

                </div>
            </div>
            <div className="row">
                <div className="col col-12">
                    <TextInput
                        label="Line 2"
                        name={"line_two_" + props.index}
                        text={geo.selectedLocation.line_two}
                        validators={[]}
                        autoComplete={"off"}
                        handleFieldErrors={handleFieldErrors}
                        changeText={
                            (text: string) => {
                                setLocationField(text, 'line_two')
                            }
                        }
                    />

                </div>
            </div>
            <div className="row">
                <div className="col col-7">
                    <TextInput
                        label="City"
                        name={"city_" + props.index}
                        required={true}
                        text={geo.selectedLocation.city}
                        autoComplete={"off"}
                        validators={[
                            (value) => validators.text(value, 'Required'),
                        ]}
                        handleFieldErrors={handleFieldErrors}
                        changeText={
                            (text: string) => {
                                setLocationField(text, 'city')
                            }
                        }
                    />
                </div>
                <div className="col col-2">
                    <TextInput
                        label="State"
                        name={"state_" + props.index}
                        required={true}
                        text={geo.selectedLocation.state}
                        autoComplete={"off"}
                        validators={[
                            (value) => validators.text(value, 'Required'),
                        ]}
                        handleFieldErrors={handleFieldErrors}
                        changeText={
                            (text: string) => {
                                setLocationField(text, 'state')
                            }
                        }
                    />
                </div>
                <div className="col col-3">
                    <TextInput
                        label="Zip"
                        name={"zip_" + props.index}
                        required={true}
                        text={geo.selectedLocation.zip}
                        autoComplete={"off"}
                        validators={[
                            (value) => validators.text(value, 'Required'),
                        ]}
                        handleFieldErrors={handleFieldErrors}
                        changeText={
                            (text: string) => {
                                setLocationField(text, 'zip')
                            }
                        }
                    />
                </div>

            </div>

            <div className="row">
                {props.final && complete &&
                    <div className="col col-12 right">
                        <Button onClick={props.addLocation} className={"link"} disabled={!props.final}>Add another
                            Location</Button>
                    </div>
                }
            </div>

            {!props.final &&

                <hr/>}
        </div>
    ) : (<></>);

};

export default LocationForm;

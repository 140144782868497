import {useForm} from '../../context/FormContext';
import Button from '../Button';
import React from "react";

const ErrorTransformer = () => {
    const form = useForm();

    const response = form?.apiResponse;

    return (<>
        <h1>{(response?.status ?? 500) >= 500 ? 'Server error' : 'Validation failed'}</h1>
        <p className="subtitle">{response?.data?.error && response.data.error}</p>
        <div className="col col-12 " style={{margin: '10px 0'}}>
            <Button onClick={() => form?.setStep(3)} className={"link "}>Return to the form</Button>
        </div>

        <div className="col col-12 ">
            <Button onClick={() => form?.submit()} className={"link"}>Re-Submit</Button>
        </div>

    </>)
}

export default ErrorTransformer

import { useHistory } from "react-router";
import BackIcon from "../Icons/BackIcon";

interface ButtonProps {
  children: JSX.Element | string;
  href?: string;
  onClick?: any;
  className?: string;
  disabled?: boolean
}

const Button = (props: ButtonProps) => {
  const history = useHistory();

  const handleClick = () => {
    if (!props.disabled) {
      if (props.href) {
        history.push(props.href);
      } else {
        props.onClick();
      }
    }
  };

  return <div className={`button ${props.className} ${props.disabled && "disabled"}`} onClick={() => handleClick()}>
    {props.className === 'back' &&
      <BackIcon />}
    <p>{props.children}</p>
  </div>;
};

export default Button;

import "./Header.scss";
import Logo from "../../assets/images/logo.svg";
import hsLogo from "../../assets/images/hslogo.png"
import threeshape from "../../assets/images/3shapelogo.svg"
import { useParams } from "react-router-dom";

type onboardingParams = {
  referral: string;
}
const NavBar = () => {



  const { referral } = useParams<onboardingParams>();
  const ref = referral?.toLowerCase();
  const codes = {
    "3shape": threeshape,
    "hs": hsLogo
  }

  return (
    <header>

      <img className="logo" src={Logo} alt="" />
      {
        referral && Object.keys(codes).includes(ref) &&
        <div className="partners">
          <p>in partnership with</p>
          <img className="partner" src={codes[ref as keyof typeof codes]} alt="" />

        </div>

      }


    </header>
  );
};

export default NavBar;

import React from "react";
import Button from "../Button";
import {LocationFormProps} from "./types";
import {Location} from "../../interfaces/Forms";
import LocationForm from "./LocationForm";
import {useApp} from "../../context/AppContext";
import {useForm} from "../../context/FormContext";
import {GeoProvider} from "../../context/GeoContext";

const LocationForms = (props: LocationFormProps) => {
    const App = useApp();
    const form = useForm();

    const [validated, setValidated] = React.useState(false);


    const newLocation: Location = {
        name: '',
        tel: '',
        text_address: '',
        line_one: '',
        line_two: '',
        city: '',
        state: '',
        zip: '',
        lat: 0,
        lng: 0,
        technology: '',

    }
    const [locations, setLocations] = React.useState<Location[]>(form?.formState.office.locations ?? [{...newLocation}]);
    const [errors, setErrors] = React.useState<boolean[]>(form?.formState.office.locations ?
        form?.formState.office.locations.map(() => {
            return (true)
        }) :
        [true])
    const addLocation = () => {
        locations.push({...newLocation});
        errors.push(true);
        setLocations([...locations]);
        setErrors([...errors]);
    }
    const removeLocation = (index: number) => {
        locations.splice(index, 1);
        errors.splice(index, 1);
        setLocations([...locations]);
        setErrors([...errors]);
    }
    const updateLocation = (index: number, location: Location) => {
        locations[index] = location;
        setLocations([...locations]);
    }

    const submitLocations = (goNextPage: boolean) => {
        if (form && form.formState.office) {
            form.formState.office.locations = [...locations];
            form.setFormState({...form.formState});

        }
        if (goNextPage) form?.next()
        if (!goNextPage) form?.back()

    }
    const setError = (index: number, error: boolean) => {
        errors[index] = error;
        setErrors([...errors]);
    }

    React.useEffect(() => {
        errors.forEach(error => {
            if (!error) {
                setValidated(false);
            } else {
                setValidated(true)
            }
        })
    }, [errors])


    return (
        <div className="form ">
            <div className="head">
                <p className="subtitle">Enter your office location and partner locations. You can add up to 10
                    locations. If you have more than 10 locations pleace contact us at {App?.phoneNumber}.</p>
                <p className="italic">
                    <span className="warn">*</span> Required Fields
                </p>
            </div>

            {
                locations.map((location, index) => {
                    return (
                        <GeoProvider key={index}
                                     location={location}
                                     updateLocation={(location: Location) =>
                                         updateLocation(index, location)}>
                            <LocationForm
                                key={index}
                                index={index}
                                addLocation={addLocation}
                                removeLocation={() =>
                                    removeLocation(index)}
                                first={index === 0}
                                final={index === 9 ?
                                    false :
                                    index === (locations.length - 1)}
                                hasErrors={(err) => setError(index, err)}/>
                        </GeoProvider>
                    )
                })
            }
            <div className="control">
                <div className="row">
                    <Button
                        onClick={() => submitLocations(true)}
                        disabled={validated}
                        className={"large"}>
                        Next: Scanner
                    </Button>
                </div>
                <div className="row">
                    <Button
                        onClick={() => submitLocations(false)}
                        className={"back"}>
                        Back
                    </Button>
                </div>


            </div>

        </div>
    )

};

export default LocationForms;

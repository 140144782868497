import React, { useState, useEffect } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useGeo } from '../../../context/GeoContext';

interface AddressInputProps {
    address: string;
    changeAddress: any;
    autoFillAddress: any;
    label?: string;
    required?: boolean;
    validators?: ((value?: string) => string | null)[];
    handleFieldErrors?: (fieldName: string, errors: string[]) => void;
    name?: string;
}

const GoogleAddressInput = (props: AddressInputProps) => {
    const geo = useGeo();
    const [address, setAddress] = useState<string>('');
    const [errors, setErrors] = useState<string[]>([]);
    const [showErr, setShowErr] = useState<boolean>(false);
    const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const saveAddress = (_address: string) => {
        setAddress(_address);
        props.changeAddress(_address);
    };


    const formatAddressAndSave = async (address: any) => {
        let formattedAddress = (await geo?.search(address) ?? []);

        if (formattedAddress.length > 0) {
            inputRef.current && inputRef.current.blur()
            props.autoFillAddress(formattedAddress[0]);
        }
    };

    const handleOnChange = (_address: any) => {
        saveAddress(_address);

        const fieldErrors: string[] = [];

        (props.validators ?? []).forEach((validator) => {
            const err = validator(_address);


            if (err) {
                fieldErrors.push(err);
            }
        });
        setErrors(fieldErrors);

        props.handleFieldErrors?.(props.name!, fieldErrors);

    }
    const handleOnBlur = () => {
        setShowSuggestions(false)
        if (errors.length > 0)
            setShowErr(true)
        else
            setShowErr(false)
    }


    useEffect(() => {
        if (props.address) {
            setAddress(props.address)
            handleOnChange(props.address)
        } else {
            setAddress('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.address]);

    return (
        <>
            <PlacesAutocomplete
                value={address}
                onChange={handleOnChange}
                onSelect={formatAddressAndSave}
            //fetchDetails={true}
            >
                {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                }) => (
                    <div className="inputs">
                        <div className="input">
                            <label>
                                {props.label}
                                {props.required && <span className='warn'>*</span>}
                            </label>
                            <input
                                {...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: 'location-search-input',
                                    type: 'text',
                                    onFocus: () => { setShowSuggestions(true); handleOnChange(address) },
                                    onBlur: handleOnBlur,
                                    name: props.name,
                                    autoComplete: 'off'

                                })}
                                ref={inputRef}
                            />
                            {errors.length > 0 && showErr && (
                                <div className="error">{errors.map((err: string, index: number) => <div key={`err-${index}`}> - {err}</div>)}</div>
                            )

                            }
                        </div>
                        <div className="autocomplete-dropdown-container">
                            {showSuggestions && loading && <div className="suggestion-item">Loading...</div>}
                            {showSuggestions && suggestions.map((suggestion, index) => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                    <div

                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,

                                        })}
                                        id={"suggestionItem_" + { index }}
                                        key={index}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        </>
    );
};

export default GoogleAddressInput;

import React from "react";
import TextInput from "../Inputs/TextInput";
import Button from "../Button";
import { AccountFormProps } from "./types";
import { validators } from './validators';
import { useForm } from "../../context/FormContext";
import { Office } from "../../interfaces/Forms";
type ErrorsList = {
    [key in keyof Office]: {
        errors: string[];
    }
}

const AccountForm = (props: AccountFormProps) => {
    const [email, setEmail] = React.useState(props.email)
    const [validated, setValidated] = React.useState(false)
    const form = useForm();

    const submitEmail = () => {
        if (!form)
            return
        if (email.length > 0) {
            form.formState.office.email = (email)
            form.setFormState({ ...form.formState })
            props.submit(email)
            form.submit();
            form.next();
  
        }
    }


    const saveEmailAndGoBack = () =>{
        if (form){
        form.formState.office.email = (email)
        form.setFormState({ ...form.formState })
        props.submit(email)
    }
        form?.back()
    }


    const [errors, setErrors] = React.useState<Partial<ErrorsList>>(props.email.length > 0 ? { email: { errors: [] } } : { email: { errors: [""] } });

    const handleFieldErrors = (field: string, err: string[]) => {

        setErrors({
            ...errors,
            [field]: {
                errors: err,
            },
        });
    }

    React.useEffect(() => {
        let _hasErrors = false;
        for (const key in errors) {
            const errorItem = errors[key as keyof Office];
            if (_hasErrors) break;

            if (errorItem && errorItem?.errors.length > 0) {
                _hasErrors = true;
            }
        }
        setValidated(!_hasErrors);
    }, [errors])

    return (
        <div className="form">
            <div className="head">
                <p className="subtitle">Enter the email you would like to use for your main office. This will be your admin username. You will be able to add additional office users once you have created your account.</p>
                <p className="italic">
                    <span className="warn">*</span> Required Fields
                </p>
            </div>
            <div className="body">

                <div className="row">
                    <div className="col col-12">
                        <TextInput
                            text={email}
                            label="Email"
                            name={"email"}
                            required={true}
                            validators={[
                                (value) => validators.email(value),
                            ]}
                            handleFieldErrors={handleFieldErrors}
                            changeText={
                                (text: string) => {
                                    setEmail(text);
                                }
                            } />
                    </div>
                </div>
            </div>
            <div className="control">
                <div className="row">
                    <Button disabled={!validated} onClick={()=>submitEmail()} className={"large"}>Submit</Button>
                </div>


                <div className="row">
                    <Button onClick={()=>saveEmailAndGoBack()} className={"back"}>Back</Button>
                </div>


            </div>

        </div>
    )

};

export default AccountForm;